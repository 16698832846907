import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { format } from "currency-formatter";
import { useMediaQuery } from "react-responsive";
import { Row, Col, Form } from "react-bootstrap";
import ReactSlider from "react-slider";
import "./slider.css";
import _ from "lodash";

const SliderComponent = ({
  minValue,
  maxValue,
  defaultValue,
  disabled,
  step,
  onChange,
  customFormat,
  isArea,
  size = "lg",
  terms,
  excludedTerms,
}) => {
  const [currentValue, setCurrentValue] = useState(0);
  const [oldTerm, setOldTerm] = useState(60);
  const [max, setMaxValue] = useState(maxValue);
  const [isFormatValue, setIsFormatValue] = useState(true);

  const isSmallSize = useMediaQuery({ maxWidth: 767 });
  const isMediumSize = useMediaQuery({ maxWidth: 991 });

  const onSlideChange = useCallback(
    (value) => {
      if (terms) {
        if (excludedTerms.includes(String(value))) {
          value = oldTerm > value ? value - step : value + step;
        }
        setOldTerm(value);
      }
      setCurrentValue(Math.round(value));
      if (onChange) {
        onChange(value);
      }
    },
    [excludedTerms, oldTerm, onChange, step, terms]
  );

  const onInputChange = useCallback(
    (inputEvent) => {
      maxValue = Number(maxValue);
      let _value = Number(inputEvent.target.value);
      if (!_.isNaN(_value) && !String(_value).includes(".")) {
        if (terms) {
          if (excludedTerms.includes(String(_value))) {
            _value = oldTerm > _value ? _value - step : _value + step;
            setOldTerm(_value);
          }
        }
        if (!onChange) {
          setCurrentValue(Math.round(_value));
          return;
        }
        if (typeof maxValue === "number" && _value > maxValue) {
          onChange(maxValue);
          setCurrentValue(Math.round(maxValue));
          return;
        }
        onChange(_value);
        setCurrentValue(Math.round(_value));
      }
    },
    [excludedTerms, onChange, maxValue, oldTerm, step]
  );

  const onInputFocus = useCallback((inputEvent) => {
    setIsFormatValue(false);
  }, []);

  const onBlur = (inputEvent) => {
    if (inputEvent.target.defaultValue > max) {
      setCurrentValue(Math.round(max));
      onChange(maxValue);
    } else if (inputEvent.target.defaultValue < minValue) {
      setCurrentValue(Math.round(minValue));
      onChange(minValue);
    } else {
      if (step) {
        const inputValue = inputEvent.target.value;
        if (inputValue % step !== 0) {
          if (terms) {
            let newValue;
            for (let i = Number(inputValue); i <= Number(max); i++) {
              for (let j = 0; j < terms.length; j++) {
                if (i === Number(terms[j])) {
                  newValue = i;
                }
              }
              if (newValue) break;
            }
            setCurrentValue(Math.round(newValue));
            onChange(newValue);
          } else {
            const remainder = (inputValue - minValue) % step;
            if (remainder < step / 2) {
              setCurrentValue(Math.round(inputValue - remainder));
            } else {
              setCurrentValue(Math.round(inputValue - remainder + step));
            }
          }
        }
      }
    }
    setIsFormatValue(true);
  };

  useEffect(() => {
    setMaxValue(maxValue);
    if (currentValue > maxValue) {
      setCurrentValue(Math.round(maxValue));
    }
  }, [maxValue]);

  useEffect(() => {
    setCurrentValue(Math.round(defaultValue));
  }, [defaultValue]);

  return (
    <Col className="mb-2">
      <Row>
        <ReactSlider
          min={minValue}
          max={max}
          step={step}
          disabled={disabled}
          value={currentValue}
          onChange={(value) => onSlideChange(value)}
          className="customSlider col-md-7 my-2"
          trackClassName="sliderTrack"
          markClassName="example-mark"
          marks={terms ? terms : [minValue, max]}
          renderThumb={(props, state) => {
            return (
              <div {...props} className="sliderThumb">
                <div className="innerCircle"> </div>
              </div>
            );
          }}
          renderMark={({ key, ...props }) => {
            if (props.style.left === 0) {
              return (
                <span
                  {...props}
                  style={{
                    ...props.style,
                    left: props.style.left,
                    color: "#333",
                    position: "absolute",
                  }}
                >
                  {customFormat ? format(minValue, customFormat) : minValue}
                </span>
              );
            } else if (terms) {
              return (
                <span
                  {...props}
                  style={{
                    ...props.style,
                    left: props.style.left - 50,
                    color: "#333",
                    position: "absolute",
                    width: "5em",
                    textAlign: "right",
                  }}
                >
                  {key <= max ? key : ""}
                </span>
              );
            } else {
              return (
                <span
                  {...props}
                  style={{
                    ...props.style,
                    left: props.style.left - 50,
                    color: "#333",
                    position: "absolute",
                    width: "5em",
                    textAlign: "right",
                  }}
                >
                  {customFormat ? format(max, customFormat) : max}
                </span>
              );
            }
          }}
        />
        <div className="col-md-1"></div>
        <Form.Control
          size={`${size}`}
          as={isArea ? "textarea" : "div"}
          className={
            "d-flex custom-color-input " +
            (isSmallSize ? "mt-4 " : "") +
            (isMediumSize ? "col-md-4" : "col-md-3")
          }
          style={{ cursor: "text" }}
        >
          <input
            inputMode="numeric"
            type="text"
            className="d-flex custom-color-input"
            style={{
              zIndex: 1000,
              textAlign: "right",
              width: "90%",
              height: "85%",
              outline: "none",
              border: "none",
              position: "absolute",
              top: "50%",
              left: "50%",
              background: "transparent",
              transform: "translate(-50%, -50%)",
            }}
            value={
              isFormatValue
                ? customFormat
                  ? format(currentValue, customFormat)
                  : currentValue
                : currentValue
            }
            onChange={onInputChange}
            onFocus={onInputFocus}
            onBlur={onBlur}
          />
        </Form.Control>
      </Row>
    </Col>
  );
};

SliderComponent.defaultProps = {
  disabled: false,
};
SliderComponent.propTypes = {
  disabled: PropTypes.bool,
  style: PropTypes.instanceOf(Object),
};

export default React.memo(SliderComponent);
