import { joiResolver } from "@hookform/resolvers/joi";
import { useCountry } from "context/country";
import { useLand } from "context/land";
import { useLeasingFormState } from "hooks/useLeasingFormState";
import Joi from "joi";
import queryString from "query-string";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { lowerObject } from "utils";
import { useLocale } from "../context/locale";
import LandingPage from "./Common/LandingPage";
import LoadingPage from "./Common/Loading";
import LeasingFormBusiness from "./LeasingFormBusiness";
import LeasingFormPrivate from "./LeasingFormPrivate";

// https://leasing.swissquote.ch/leasing-form/@model?
// country=@lowercase_language_code_@country_code&
// orderID=@reference_number&
// price=@vehicle_price&
// options=@configuration&
// customer=@customer_type&
// vehicle=@vehicle_type

// https://leasing.swissquote.ch/leasing-form/Model3/?
// country=it_CH
// &orderID=RN144555937
// &price=4598000
// &options=$APBS,$DV2W,$IBB0,$PPSW,$PRM30,$SC04,$MDL3,$W40B,$MT314,$CPF0
// &customer=private
// &vehicle=new

export default function LeasingFormScreen(props) {
  const { search } = useLocation();
  const { carType } = useParams();
  const { push } = useHistory();
  const { formatMessage } = useIntl();
  const { setLocale, locale } = useLocale();
  const { countryContext } = useCountry();
  const { land } = useLand();
  const customer = lowerObject(queryString.parse(search))[
    "customer"
  ].toLowerCase();
  const { setLeasingFormState, setDefaultValue } = useLeasingFormState();

  // Validation scheme for calculator screen
  const contractValidFieldsScreen1 = useMemo(
    () => ({
      laufleistung: Joi.number()
        .label(formatMessage({ id: "calculator.text.laufleistung" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
          "number.base": formatMessage({ id: "validation.text.number" }),
        }),
      laufzeit: Joi.number()
        .label(formatMessage({ id: "calculator.text.laufleistung" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
          "number.base": formatMessage({ id: "validation.text.number" }),
        }),
      kaufpreis: Joi.number()
        .label(formatMessage({ id: "calculator.input.barkaufpreis" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
          "number.base": formatMessage({ id: "validation.text.number" }),
        }),
      anzahlung: Joi.number()
        .label(formatMessage({ id: "calculator.text.anzahlung" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
          "number.base": formatMessage({ id: "validation.text.number" }),
        }),
      leasingrate: Joi.number()
        .label(formatMessage({ id: "calculator.text.leasingrate" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
          "number.base": formatMessage({ id: "validation.text.number" }),
        }),
      restwert: Joi.number()
        .label(formatMessage({ id: "calculator.text.restwert" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
          "number.base": formatMessage({ id: "validation.text.number" }),
        }),
      model: Joi.string()
        .label(formatMessage({ id: "calculator.text.modell" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      orderID: Joi.string()
        .label(formatMessage({ id: "calculator.text.orderID" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      carOptions: Joi.string()
        .label(formatMessage({ id: "calculator.input.carOptions" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      customerTypePrivate: Joi.boolean()
        .label(formatMessage({ id: "calculator.input.customerTypePrivate" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      vehicleTypeNew: Joi.boolean()
        .label(formatMessage({ id: "calculator.input.vehicleTypeNew" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      nomInterest: Joi.number()
        .label(formatMessage({ id: "calculator.input.nomInterest" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
          "number.base": formatMessage({ id: "validation.text.number" }),
        }),
      effInterest: Joi.number()
        .label(formatMessage({ id: "calculator.input.effInterest" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
          "number.base": formatMessage({ id: "validation.text.number" }),
        }),
    }),
    [formatMessage]
  );

  // Validation scheme for personal screen
  const personValidFieldsScreen2 = useMemo(
    () => ({
      sprache: Joi.string()
        .label(formatMessage({ id: "data.input.name" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      anrede: Joi.string()
        .label(formatMessage({ id: "data.button.salutation" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      vorname: Joi.string()
        .label(formatMessage({ id: "data.input.name" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      nachname: Joi.string()
        .label(formatMessage({ id: "data.input.surname" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      zivilstand: Joi.string()
        .label(formatMessage({ id: "data.input.civilstatus" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      geburtstag: Joi.date()
        .label(formatMessage({ id: "data.input.birthday" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      nationalitaet: Joi.object()
        .label(formatMessage({ id: "data.input.nationality" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      ausweis: Joi.string()
        .label(formatMessage({ id: "data.button.foreignersIdentityCard" }))
        .required(),
      land: Joi.object()
        .label(formatMessage({ id: "data.input.country" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      plz: Joi.string()
        .label(formatMessage({ id: "data.input.postcode" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      ort: Joi.string()
        .min(3)
        .label(formatMessage({ id: "data.input.postcode" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      strasse: Joi.string()
        .min(3)
        .label(formatMessage({ id: "data.input.street" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      nummer: Joi.string()
        .label(formatMessage({ id: "data.input.street" }))
        .trim()
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      wohnhaftSeit: Joi.object()
        .label(formatMessage({ id: "data.input.addressSince" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
          "number.base": formatMessage({ id: "validation.text.number" }),
        }),
      PersonWeitereTelefonnummer: Joi.string()
        .label(formatMessage({ id: "data.input.phone" }))
        .required(),
      mobil: Joi.string()
        .label(formatMessage({ id: "data.input.phone" }))
        .allow(""),
      privat: Joi.string()
        .label(formatMessage({ id: "data.input.phone" }))
        .allow(""),
      geschaeft: Joi.string()
        .label(formatMessage({ id: "data.input.phone" }))
        .allow(""),
      weitereTelefonnummer: Joi.string()
        .label(formatMessage({ id: "data.input.phone" }))
        .allow(""),
      telephoneNumber: Joi.string()
        .label(formatMessage({ id: "data.input.phone" }))
        .pattern(/^[+(\s.\-/\d)]{5,30}$/)
        .required(),
      email: Joi.string()
        .label(formatMessage({ id: "data.input.email" }))
        .email({ tlds: { allow: false } })
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
          "string.email": formatMessage({ id: "validation.text.email" }),
        }),
      beneficial: Joi.string()
        .label(formatMessage({ id: "data.button.beneficial" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      naturalPerson: Joi.string()
        .label(formatMessage({ id: "data.title.whoIsBeneficialOwner" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      companynamejurP: Joi.string()
        .required()
        .label(formatMessage({ id: "data.input.company" }))
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      salutationKInh: Joi.string()
        .label(formatMessage({ id: "data.button.salutation" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      firstnameKInh: Joi.string()
        .label(formatMessage({ id: "data.input.name" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      lastnameKInh: Joi.string()
        .label(formatMessage({ id: "data.input.surname" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      postcodejurP: Joi.string()
        .label(formatMessage({ id: "data.input.postcode" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      placejurP: Joi.string()
        .min(3)
        .label(formatMessage({ id: "data.input.postcode" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      streetjurP: Joi.string()
        .min(3)
        .label(formatMessage({ id: "data.input.street" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      numberjurP: Joi.string()
        .label(formatMessage({ id: "data.input.street" }))
        .trim()
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      postcodeKInh: Joi.string()
        .label(formatMessage({ id: "data.input.postcode" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      placeKInh: Joi.string()
        .min(3)
        .label(formatMessage({ id: "data.input.postcode" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      streetKInh: Joi.string()
        .min(3)
        .label(formatMessage({ id: "data.input.street" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      numberKInh: Joi.string()
        .label(formatMessage({ id: "data.input.street" }))
        .trim()
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      birthdayKInh: Joi.date()
        .label(formatMessage({ id: "data.input.birthday" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      nationalityKInh: Joi.object()
        .label(formatMessage({ id: "data.input.nationality" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      countryKInh: Joi.object()
        .label(formatMessage({ id: "data.input.country" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      landKInh: Joi.object()
        .label(formatMessage({ id: "data.input.country" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
    }),
    [formatMessage]
  );

  // Validation scheme for private financial screen
  // if customer is vermoegend, then fields: arbeitgeber, PLZArbeitgeber, ortArbeitgeber, strasseArbeitgeber, hausnummerArbeitgeber are optional
  // customer is vermoegend if vermoegen is greater than 1 million and customer does not have a Anstellungsverhältnis
  const financialsValidFieldsScreen3 = useMemo(
    () => ({
      landArbeitgeber: Joi.object()
        .label(formatMessage({ id: "income.input.companyCountry" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      arbeitgeber: Joi.string()
        .label(formatMessage({ id: "income.input.company" }))
        .when("anstellungsverhaeltnis", {
          not: "Ohne Anstellungsverhältnis",
          then: Joi.string().required(),
          otherwise: Joi.when("vermoegen", {
            is: Joi.number().greater(1000000),
            then: Joi.optional().allow(""),
          }),
        })
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      PLZArbeitgeber: Joi.string()
        .label(formatMessage({ id: "income.input.companyPostcode" }))
        .when("anstellungsverhaeltnis", {
          not: "Ohne Anstellungsverhältnis",
          then: Joi.string().required(),
          otherwise: Joi.when("vermoegen", {
            is: Joi.number().greater(1000000),
            then: Joi.optional().allow(""),
          }),
        })
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      ortArbeitgeber: Joi.string()
        .label(formatMessage({ id: "income.input.companyPostcode" }))
        .when("anstellungsverhaeltnis", {
          not: "Ohne Anstellungsverhältnis",
          then: Joi.string().min(3).required(),
          otherwise: Joi.when("vermoegen", {
            is: Joi.number().greater(1000000),
            then: Joi.optional().allow(""),
          }),
        })
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      strasseArbeitgeber: Joi.string()
        .label(formatMessage({ id: "income.input.companyStreet" }))
        .when("anstellungsverhaeltnis", {
          not: "Ohne Anstellungsverhältnis",
          then: Joi.string().min(3).required(),
          otherwise: Joi.when("vermoegen", {
            is: Joi.number().greater(1000000),
            then: Joi.optional().allow(""),
          }),
        })
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      hausnummerArbeitgeber: Joi.string()
        .label(formatMessage({ id: "income.input.companyStreet" }))
        .when("anstellungsverhaeltnis", {
          not: "Ohne Anstellungsverhältnis",
          then: Joi.string().trim().required(),
          otherwise: Joi.when("vermoegen", {
            is: Joi.number().greater(1000000),
            then: Joi.optional().allow(""),
          }),
        })
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      anstellungsverhaeltnis: Joi.string()
        .label(formatMessage({ id: "income.input.employment" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      angestelltSeit: Joi.date()
        .label(formatMessage({ id: "income.input.employedSince" }))
        .when("anstellungsverhaeltnis", {
          not: "Ohne Anstellungsverhältnis",
          then: Joi.required(),
          otherwise: Joi.optional().allow(""),
        }),
      angestelltBis: Joi.date()
        .label(formatMessage({ id: "income.input.limitedUntil" }))
        .when("anstellungsverhaeltnis", [
          {
            is: "Temporär",
            then: Joi.required(),
          },
          {
            is: "Befristet",
            then: Joi.required(),
          },
        ]),
      vermoegen: Joi.number()
        .label(formatMessage({ id: "profession.input.vermoegen" }))
        .when("anstellungsverhaeltnis", {
          is: "Ohne Anstellungsverhältnis",
          then: Joi.number().required(),
          otherwise: Joi.optional().allow(""),
        }),
      monatlichesNettoEinkommen: Joi.number()
        .label(formatMessage({ id: "income.input.income" }))
        .when("anstellungsverhaeltnis", {
          not: "Ohne Anstellungsverhältnis",
          then: Joi.number().required(),
          otherwise: Joi.optional().allow(""),
        })
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
          "number.base": formatMessage({ id: "validation.text.number" }),
        }),
      dreizehnterMonatslohn: Joi.string()
        .label(formatMessage({ id: "income.button.additionalMonthPayment" }))
        .when("anstellungsverhaeltnis", {
          not: "Ohne Anstellungsverhältnis",
          then: Joi.string().required(),
          otherwise: Joi.optional().allow(""),
        })
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      gratifikation: Joi.string()
        .label(formatMessage({ id: "income.button.bonus" }))
        .when("anstellungsverhaeltnis", {
          not: "Ohne Anstellungsverhältnis",
          then: Joi.string().required(),
          otherwise: Joi.optional().allow(""),
        })
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      durchschnittGratifikation: Joi.number()
        .integer()
        .min(1)
        .label(
          formatMessage({ id: "profession.input.durchschnittGratifikation" })
        )
        .required()
        .messages({
          "number.base": formatMessage({ id: "validation.text.number" }),
          "number.min": formatMessage({ id: "validation.text.numberisnot0" }),
        }),
      nebenerwerb: Joi.number()
        .integer()
        .min(1)
        .label(formatMessage({ id: "profession.input.nebenerwerb" }))
        .required()
        .messages({
          "number.base": formatMessage({ id: "validation.text.number" }),
          "number.min": formatMessage({ id: "validation.text.numberisnot0" }),
        }),
      nebenerwerbChecked: Joi.string()
        .when("anstellungsverhaeltnis", {
          not: "Ohne Anstellungsverhältnis",
          then: Joi.string().required(),
          otherwise: Joi.optional().allow(""),
        })
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
    }),
    [formatMessage]
  );

  const financialsValidFieldsScreen4 = useMemo(
    () => ({
      wohnsituation: Joi.string()
        .label(formatMessage({ id: "expenses.button.housingSituation" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      wohnMietkosten: Joi.number()
        .label(formatMessage({ id: "expenses.input.rent" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
          "number.base": formatMessage({ id: "validation.text.number" }),
        }),
      kinderHaushalt: Joi.string()
        .label(formatMessage({ id: "expenses.button.childrenInHousehold" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      bisSechsJahre: Joi.number()
        .label(formatMessage({ id: "expenses.button.childrenInHousehold" }))
        .optional()
        .allow("")
        .messages({
          "number.base": formatMessage({ id: "validation.text.number" }),
        }),
      bisZwoelfJahre: Joi.number()
        .label(formatMessage({ id: "expenses.button.childrenInHousehold" }))
        .optional()
        .allow("")
        .messages({
          "number.base": formatMessage({ id: "validation.text.number" }),
        }),
      abZwoelfJahre: Joi.number()
        .label(formatMessage({ id: "expenses.button.childrenInHousehold" }))
        .optional()
        .allow("")
        .messages({
          "number.base": formatMessage({ id: "validation.text.number" }),
        }),
      alimente: Joi.number()
        .integer()
        .min(1)
        .label(formatMessage({ id: "expenses.button.alimony" }))
        .required()
        .messages({
          "number.base": formatMessage({ id: "validation.text.number" }),
          "number.min": formatMessage({ id: "validation.text.numberisnot0" }),
        }),
      andereRegelAuslagen: Joi.number()
        .label(formatMessage({ id: "expenses.input.otherExpenses" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
          "number.base": formatMessage({ id: "validation.text.number" }),
        }),
      Vertragersatz: Joi.number()
        .integer()
        .min(1)
        .label(formatMessage({ id: "expenses.button.replaceExistingContract" }))
        .required()
        .messages({
          "number.base": formatMessage({ id: "validation.text.number" }),
          "number.min": formatMessage({ id: "validation.text.numberisnot0" }),
        }),
      Betreibungen: Joi.string()
        .label(formatMessage({ id: "expenses.button.debtCollection" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      alimenteChecked: Joi.string()
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      VertragersatzChecked: Joi.string()
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      Bemerkungen: Joi.string()
        .label(formatMessage({ id: "expenses.input.Comments" }))
        .optional()
        .allow(""),
      vermoegen: Joi.number()
        .label(formatMessage({ id: "living.input.vermoegen" }))
        .optional()
        .allow("")
        .messages({
          "number.base": formatMessage({ id: "validation.text.number" }),
        }),
      bestLeasingVert: Joi.number()
        .label(formatMessage({ id: "expenses.input.existingLeasing" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
          "number.base": formatMessage({ id: "validation.text.number" }),
        }),
      Fahrwegkosten: Joi.number()
        .label(formatMessage({ id: "expenses.input.travelCost" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
          "number.base": formatMessage({ id: "validation.text.number" }),
        }),
      BegruendungWohnen: Joi.string()
        .label(formatMessage({ id: "expenses.input.justification" }))
        .when("wohnMietkosten", {
          is: 0,
          then: Joi.required(),
          otherwise: Joi.optional().allow(""),
        })
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      livingConfirm: Joi.boolean()
        .required()
        .label(formatMessage({ id: "expenses.button.confirmation" }))
        .invalid(false),
      livingAgree: Joi.boolean()
        .required()
        .label(formatMessage({ id: "expenses.button.agreement" }))
        .invalid(false),
      livingContract: Joi.boolean()
        .required()
        .label(formatMessage({ id: "expenses.button.agreement" }))
        .invalid(false),
    }),
    [formatMessage]
  );

  // Validation scheme for company screen
  const companyBusinessValid = useMemo(
    () => ({
      companyName: Joi.string()
        .label(formatMessage({ id: "company.input.companyName" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      companyStreet: Joi.string()
        .min(3)
        .label(formatMessage({ id: "company.input.street" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      companyNo: Joi.string()
        .label(formatMessage({ id: "company.input.street" }))
        .trim()
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      companyZIP: Joi.string()
        .label(formatMessage({ id: "company.input.pLZ/Ort" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      companyPlace: Joi.string()
        .min(3)
        .label(formatMessage({ id: "company.input.pLZ/Ort" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      companyCountry: Joi.object()
        .label(formatMessage({ id: "company.input.country" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      companyFounded: Joi.object()
        .label(formatMessage({ id: "company.input.companyFounded" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
          "number.base": formatMessage({ id: "validation.text.number" }),
        }),
      companyUID: Joi.string()
        .label(formatMessage({ id: "company.input.companyUID" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      companyEmployees: Joi.number()
        .label(formatMessage({ id: "company.input.companyEmployees" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      betreibungen: Joi.string()
        .label(formatMessage({ id: "company.text.enforcements" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      remarks: Joi.string()
        .label(formatMessage({ id: "company.input.remarks" }))
        .optional()
        .allow("")
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
    }),
    [formatMessage]
  );

  // Validation scheme for business financial screen
  const financialBusinessValid = useMemo(
    () => ({
      pastTurnover2: Joi.number()
        .label(formatMessage({ id: "financials.input.pastRevenues" }))
        .required()
        .messages({
          "string.empty": formatMessage({
            id: "validation.text.stringEmpty",
          }),
          "number.base": formatMessage({ id: "validation.text.number" }),
        }),
      pastTurnover1: Joi.number()
        .label(formatMessage({ id: "financials.input.pastRevenues" }))
        .required()
        .messages({
          "string.empty": formatMessage({
            id: "validation.text.stringEmpty",
          }),
          "number.base": formatMessage({ id: "validation.text.number" }),
        }),
      pastResult2: Joi.number()
        .label(formatMessage({ id: "financials.input.pastResults" }))
        .required()
        .messages({
          "string.empty": formatMessage({
            id: "validation.text.stringEmpty",
          }),
          "number.base": formatMessage({ id: "validation.text.number" }),
        }),
      pastResult1: Joi.number()
        .label(formatMessage({ id: "financials.input.pastResults" }))
        .required()
        .messages({
          "string.empty": formatMessage({
            id: "validation.text.stringEmpty",
          }),
          "number.base": formatMessage({ id: "validation.text.number" }),
        }),
      pastEquity2: Joi.number()
        .label(formatMessage({ id: "financials.input.equity" }))
        .required()
        .messages({
          "string.empty": formatMessage({
            id: "validation.text.stringEmpty",
          }),
          "number.base": formatMessage({ id: "validation.text.number" }),
        }),
      pastEquity1: Joi.number()
        .label(formatMessage({ id: "financials.input.equity" }))
        .required()
        .messages({
          "string.empty": formatMessage({
            id: "validation.text.stringEmpty",
          }),
          "number.base": formatMessage({ id: "validation.text.number" }),
        }),
      // actualRevenue: Joi.number()
      //   .label(formatMessage({ id: "financials.input.averageRevenue" }))
      //   .required()
      //   .messages({
      //     "string.empty": formatMessage({
      //       id: "validation.text.stringEmpty",
      //     }),
      //     "number.base": formatMessage({ id: "validation.text.number" }),
      //   }),
      // monthlyMean: Joi.number()
      //   .label(formatMessage({ id: "financials.input.averageResult" }))
      //   .required()
      //   .messages({
      //     "string.empty": formatMessage({
      //       id: "validation.text.stringEmpty",
      //     }),
      //     "number.base": formatMessage({ id: "validation.text.number" }),
      //   }),
      existingContracts: Joi.number()
        .label(formatMessage({ id: "financials.input.existingContracts" }))
        .required()
        .messages({
          "string.empty": formatMessage({
            id: "validation.text.stringEmpty",
          }),
          "number.base": formatMessage({ id: "validation.text.number" }),
        }),
      replaceExitContractChecked: Joi.string()
        .label(formatMessage({ id: "financials.input.attachablepart" }))
        .required()
        .messages({
          "string.empty": formatMessage({
            id: "validation.text.stringEmpty",
          }),
        }),
      attachablepart: Joi.number()
        .label(formatMessage({ id: "financials.input.attachablepart" }))
        .when("replaceExitContractChecked", {
          not: "Nein",
          then: Joi.number().required(),
          otherwise: Joi.optional().allow(""),
        })
        .messages({
          "string.empty": formatMessage({
            id: "validation.text.stringEmpty",
          }),
          "number.base": formatMessage({ id: "validation.text.number" }),
        }),
    }),
    [formatMessage]
  );

  // Validation scheme for confirmation screen
  const confirmationBusinessValid = useMemo(
    () => ({
      checkBox1: Joi.boolean()
        .required()
        .label(formatMessage({ id: "confirmation.button.checkBox1" }))
        .invalid(false),
      checkBox2: Joi.boolean()
        .required()
        .label(formatMessage({ id: "confirmation.button.checkBox2" }))
        .invalid(false),
      checkBox3: Joi.boolean()
        .required()
        .label(formatMessage({ id: "confirmation.button.checkBox3" }))
        .invalid(false),
    }),
    [formatMessage]
  );

  // Validation scheme for people screen
  const peopleBusinessValid = useMemo(
    () => ({
      salutation: Joi.string()
        .label(formatMessage({ id: "contact.input.salutation" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      firstName: Joi.string()
        .label(formatMessage({ id: "contact.input.firstName" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      lastName: Joi.string()
        .label(formatMessage({ id: "contact.input.lastName" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      birthday: Joi.date()
        .label(formatMessage({ id: "contact.input.birthday" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      nationality: Joi.object()
        .label(formatMessage({ id: "contact.input.nationality" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      street: Joi.string()
        .min(3)
        .label(formatMessage({ id: "contact.input.street" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      number: Joi.string()
        .label(formatMessage({ id: "contact.input.street" }))
        .trim()
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      zip: Joi.string()
        .label(formatMessage({ id: "contact.input.postcode" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      place: Joi.string()
        .min(3)
        .label(formatMessage({ id: "contact.input.postcode" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      country: Joi.object()
        .label(formatMessage({ id: "contact.input.country" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      role: Joi.string()
        .label(formatMessage({ id: "contact.input.role" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      personPhone: Joi.string()
        .label(formatMessage({ id: "contact.input.phoneNumber" }))
        .required(),
      mobile: Joi.string()
        .label(formatMessage({ id: "contact.input.phoneNumber" }))
        .allow(""),
      private: Joi.string()
        .label(formatMessage({ id: "contact.input.phoneNumber" }))
        .allow(""),
      business: Joi.string()
        .label(formatMessage({ id: "contact.input.phoneNumber" }))
        .allow(""),
      otherPhone: Joi.string()
        .label(formatMessage({ id: "contact.input.phoneNumber" }))
        .allow(""),
      telephoneNumber: Joi.string()
        .label(formatMessage({ id: "data.input.phone" }))
        .pattern(/^[+(\s.\-/\d)]{5,30}$/)
        .required(),
      email: Joi.string()
        .label(formatMessage({ id: "contact.input.email" }))
        .email({ tlds: { allow: false } })
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
          "string.email": formatMessage({ id: "validation.text.email" }),
        }),
      "2ndAuthority": Joi.string()
        .label(formatMessage({ id: "contact.button.2ndAuthority" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      salutation2: Joi.string()
        .label(formatMessage({ id: "contact.input.salutation" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      firstName2: Joi.string()
        .label(formatMessage({ id: "contact.input.firstName" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      lastName2: Joi.string()
        .label(formatMessage({ id: "contact.input.lastName" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      birthday2: Joi.date()
        .label(formatMessage({ id: "contact.input.birthday" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      nationality2: Joi.object()
        .label(formatMessage({ id: "contact.input.nationality" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      street2: Joi.string()
        .min(3)
        .label(formatMessage({ id: "contact.input.street" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      number2: Joi.string()
        .label(formatMessage({ id: "contact.input.street" }))
        .trim()
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      zip2: Joi.string()
        .label(formatMessage({ id: "contact.input.postcode" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      place2: Joi.string()
        .min(3)
        .label(formatMessage({ id: "contact.input.postcode" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      country2: Joi.object()
        .label(formatMessage({ id: "contact.input.country" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      MDRole2: Joi.string()
        .label(formatMessage({ id: "contact.input.MDRole2" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      email2: Joi.string()
        .label(formatMessage({ id: "contact.input.email" }))
        .email({ tlds: { allow: false } })
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
          "string.email": formatMessage({ id: "validation.text.email" }),
        }),
      driver: Joi.string()
        .label(formatMessage({ id: "contact.button.driver" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      salutationDriver: Joi.string()
        .label(formatMessage({ id: "contact.input.salutation" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      firstNameDriver: Joi.string()
        .label(formatMessage({ id: "contact.input.firstName" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      lastNameDriver: Joi.string()
        .label(formatMessage({ id: "contact.input.lastName" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      birthdayDriver: Joi.date()
        .label(formatMessage({ id: "contact.input.birthday" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      streetDriver: Joi.string()
        .min(3)
        .label(formatMessage({ id: "contact.input.street" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      numberDriver: Joi.string()
        .label(formatMessage({ id: "contact.input.street" }))
        .trim()
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      zipDriver: Joi.string()
        .label(formatMessage({ id: "contact.input.postcode" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      placeDriver: Joi.string()
        .min(3)
        .label(formatMessage({ id: "contact.input.postcode" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      nationalityDriver: Joi.object()
        .label(formatMessage({ id: "contact.input.nationality" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      controlHolder: Joi.string()
        .label(formatMessage({ id: "contact.input.controlHolder" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      salutationKInh1: Joi.string()
        .label(formatMessage({ id: "contact.input.salutation" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      firstNameKInh1: Joi.string()
        .label(formatMessage({ id: "contact.input.firstName" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      lastNameKInh1: Joi.string()
        .label(formatMessage({ id: "contact.input.lastName" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      birthdayKInh1: Joi.date()
        .label(formatMessage({ id: "contact.input.birthday" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      streetKInh1: Joi.string()
        .min(3)
        .label(formatMessage({ id: "contact.input.street" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      numberKInh1: Joi.string()
        .label(formatMessage({ id: "contact.input.street" }))
        .trim()
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      postcodeKInh1: Joi.string()
        .label(formatMessage({ id: "contact.input.postcode" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      placeKInh1: Joi.string()
        .min(3)
        .label(formatMessage({ id: "contact.input.postcode" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      countryKInh1: Joi.object()
        .label(formatMessage({ id: "contact.input.country" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      furtherPerson: Joi.string()
        .label(formatMessage({ id: "contact.button.furtherPerson" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      salutationKInh2: Joi.string()
        .label(formatMessage({ id: "contact.input.salutation" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      firstNameKInh2: Joi.string()
        .label(formatMessage({ id: "contact.input.firstName" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      lastNameKInh2: Joi.string()
        .label(formatMessage({ id: "contact.input.lastName" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      birthdayKInh2: Joi.date()
        .label(formatMessage({ id: "contact.input.birthday" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      streetKInh2: Joi.string()
        .min(3)
        .label(formatMessage({ id: "contact.input.street" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      numberKInh2: Joi.string()
        .label(formatMessage({ id: "contact.input.street" }))
        .trim()
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      postcodeKInh2: Joi.string()
        .label(formatMessage({ id: "contact.input.postcode" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      placeKInh2: Joi.string()
        .min(3)
        .label(formatMessage({ id: "contact.input.postcode" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
      countryKInh2: Joi.object()
        .label(formatMessage({ id: "contact.input.country" }))
        .required()
        .messages({
          "string.empty": formatMessage({ id: "validation.text.stringEmpty" }),
        }),
    }),
    [formatMessage]
  );

  const schema = useMemo(
    () =>
      Joi.object({
        ...contractValidFieldsScreen1,
        ...personValidFieldsScreen2,
        ...financialsValidFieldsScreen3,
        ...financialsValidFieldsScreen4,
        ...companyBusinessValid,
        ...peopleBusinessValid,
        ...financialBusinessValid,
        ...confirmationBusinessValid,
      }),
    [
      companyBusinessValid,
      confirmationBusinessValid,
      contractValidFieldsScreen1,
      financialBusinessValid,
      financialsValidFieldsScreen3,
      financialsValidFieldsScreen4,
      peopleBusinessValid,
      personValidFieldsScreen2,
    ]
  );
  const methods = useForm({
    defaultValues: {},
    resolver: joiResolver(schema),
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const [loading, setLoading] = useState(true);

  // Set locale when locale from url is changed
  useEffect(() => {
    setLocale(
      lowerObject(queryString.parse(search))
        ["country"].split("_")[0]
        .toLowerCase()
    );
  }, [lowerObject(queryString.parse(search))["country"]]);

  useEffect(() => {
    const schema = Joi.object().keys({
      country: Joi.string()
        .label(formatMessage({ id: "calculator.text.country" }))
        .required(),
      orderid: Joi.string()
        .label(formatMessage({ id: "calculator.text.orderID" }))
        .required(),
      price: Joi.number()
        .label(formatMessage({ id: "calculator.text.price" }))
        .required(),
      options: Joi.string()
        .label(formatMessage({ id: "calculator.text.options" }))
        .required(),
      customer: Joi.string()
        .label(formatMessage({ id: "calculator.text.customer" }))
        .valid("private", "business")
        .insensitive(),
      vehicle: Joi.string()
        .label(formatMessage({ id: "calculator.text.vehicle" }))
        .valid("new", "inventory")
        .insensitive(),
    });

    const lowerObj = lowerObject({
      ...queryString.parse(search),
      country: `${locale}_CH`,
    });

    const { value, error } = schema.validate(
      {
        ...lowerObj,
        country: `${locale}_CH`,
      },
      {
        allowUnknown: true,
        abortEarly: true,
      }
    );
    // Create initial data
    const init = async () => {
      try {
        const countries = (countryContext?.returnCountry || []).map((item) => ({
          value: item.isoCode,
          label: item[locale],
        }));
        const lands = (land?.returnCountry || []).map((item) => ({
          value: item.isoCode,
          label: item[locale],
        }));
        const defaultCountryISOCode = "CH";
        const defaultCountyISOCode = "ZH";
        const defaultCountry =
          countries.find((item) => item.value === defaultCountryISOCode) || {};
        const defaultLand =
          lands.find((item) => item.value === defaultCountryISOCode) || {};
        // const defaultCounty =
        //   county.find((item) => item.value === defaultCountyISOCode) || {};

        function split(str) {
          const match = str.match(/\d/);
          if (match) {
            return [str.slice(0, match.index), str.slice(match.index)];
          }
          return [str];
        }
        function isUpper(str) {
          return /[a-z]/.test(str) && /[A-Z]/.test(str);
        }
        function containsNumber(str) {
          return /[0-9]/.test(str);
        }
        function containsWhitespace(str) {
          return /\s/.test(str);
        }
        const setter = methods.setValue.bind(methods);

        let carTypeValue = "";
        // check if string is number
        if (Number(carType)) {
          carTypeValue = String(carType);
        } else if (containsWhitespace(carType)) {
          carTypeValue = carType;
        }
        // check if string contains letters + number
        else if (containsNumber(String(carType))) {
          let stringArray = split(String(carType));
          carTypeValue = stringArray[0] + " " + stringArray[1];
        }
        // check if string contains upper case
        else if (isUpper(String(carType))) {
          console.log(String(carType));
          let stringArray = String(carType).split(/(?=[A-Z])/);
          console.log(stringArray.length);

          if (stringArray.length === 1) {
            carTypeValue = stringArray[0];
          } else if (stringArray.length > 0) {
            carTypeValue = stringArray[0] + " " + stringArray[1];
          }
        }
        // if first letter is caps
        else {
          carTypeValue = String(carType).carTypeValue;
        }

        // let carTypeValue = String(carType).toLowerCase().replace(/\s/g, "");
        // console.log(carTypeValue);
        // carTypeValue = carTypeValue[0].toUpperCase() + carTypeValue.slice(1);
        // carTypeValue = /[0-9]/.test(carTypeValue)
        //   ? `${carTypeValue.substr(
        //       0,
        //       carTypeValue.length - 1
        //     )} ${carTypeValue.substr(-1).toLocaleUpperCase()}`
        //   : carTypeValue;
        // console.log(carTypeValue);

        const initValues = {
          isCheckPopUpBlocked: false,
          naturalPerson: "Ja",
          validPages: [1],
          isSupport: "false",
          contractID: "",
          contractData: {},
          status: 200,
          prospectID: "",
          prospectActive: false,
          countries: countries,
          defaultCountry: defaultCountry,
          defaultLand: defaultLand,
          carOptions: value.options,
          customerTypePrivate: value.customer.toLowerCase() === "private",
          model: carTypeValue,
          carPrice: (value.price / 100).toFixed(2),
          country: value.country.substr(0, 2) || defaultCountryISOCode,
          PersonWeitereTelefonnummer: "defaultTelephone",
          sprache: value.country.substr(0, 2),
          anrede: "",
          vorname: "",
          nachname: "",
          companynamejurP: "",
          geburtstag: "",
          salutationKInh: "",
          firstnameKInh: "",
          lastnameKInh: "",
          birthdayKInh: "",
          zivilstand: "",
          nationalitaet: defaultCountry,
          nationalityKInh: defaultCountry,
          ausweis: "",
          lands: lands,
          land: defaultCountry,
          county: "",
          landArbeitgeber: defaultLand,
          landKInh: defaultLand,
          countryKInh: defaultLand,
          plz: "",
          ort: "",
          strasse: "",
          nummer: "",
          postcodejurP: "",
          placejurP: "",
          streetjurP: "",
          numberjurP: "",
          postcodeKInh: "",
          placeKInh: "",
          streetKInh: "",
          numberKInh: "",
          wohnhaftSeit: 0,
          mobil: "",
          privat: "",
          geschaeft: "",
          weitereTelefonnummer: "",
          telephoneNumber: "",
          email: "",
          laufleistung: 0,
          laufzeit: 0,
          kaufpreis: 0,
          anzahlung: 0,
          leasingrate: 0,
          restwert: 0,
          orderID: value.orderid || "",
          vehicleTypeNew: value.vehicle.toLowerCase() === "new" || true,
          nomInterest: 0,
          effInterest: 0,
          arbeitgeber: "",
          PLZArbeitgeber: "",
          ortArbeitgeber: "",
          strasseArbeitgeber: "",
          hausnummerArbeitgeber: "",
          angestelltSeit: "",
          monatlichesNettoEinkommen: "",
          dreizehnterMonatslohn: "",
          gratifikation: "",
          durchschnittGratifikation: "",
          nebenerwerb: "",
          wohnsituation: "",
          wohnMietkosten: "",
          kinderHaushalt: "",
          bisSechsJahre: 0,
          bisZwoelfJahre: 0,
          abZwoelfJahre: 0,
          alimente: "",
          alimenteChecked: "",
          andereRegelAuslagen: "",
          VertragersatzChecked: "",
          Vertragersatz: "",
          Betreibungen: "",
          Bemerkungen: "",
          livingAgree: false,
          livingConfirm: false,
          livingContract: false,
          vermoegen: "",
          bestLeasingVert: "",
          Fahrwegkosten: "",
          BegruendungWohnen: "",
          nebenerwerbChecked: "",
          anstellungsverhaeltnis: "",
          beneficial: "Nein",

          companyName: "",
          companyStreet: "",
          companyNo: "",
          companyZIP: "",
          companyPlace: "",
          companyCountry: defaultCountry,
          companyCounty: "",
          companyFounded: 0,
          companyUID: "",
          companyEmployees: "",
          betreibungen: "",
          remarks: "",

          pastTurnover2: "",
          pastTurnover1: "",
          pastResult2: "",
          pastResult1: "",
          pastEquity2: "",
          pastEquity1: "",
          actualRevenue: "",
          monthlyMean: "",
          existingContracts: "",
          attachablepart: "",
          replaceExitContractChecked: "",

          checkBox1: false,
          checkBox2: false,
          checkBox3: false,

          salutation: "",
          firstName: "",
          lastName: "",
          birthday: "",
          nationality: defaultLand,
          street: "",
          number: "",
          zip: "",
          place: "",
          country: defaultCountry,
          role: "",
          mobile: "",
          private: "",
          business: "",
          otherPhone: "",
          "2ndAuthority": "",
          salutation2: "",
          firstName2: "",
          lastName2: "",
          birthday2: "",
          nationality2: defaultLand,
          street2: "",
          number2: "",
          zip2: "",
          place2: "",
          country2: defaultCountry,
          MDRole2: "",
          email2: "",
          driver: "",
          salutationDriver: "",
          firstNameDriver: "",
          lastNameDriver: "",
          birthdayDriver: "",
          nationalityDriver: "",
          controlHolder: "",
          salutationKInh1: "",
          firstNameKInh1: "",
          lastNameKInh1: "",
          birthdayKInh1: "",
          streetKInh1: "",
          numberKInh1: "",
          postcodeKInh1: "",
          placeKInh1: "",
          countryKInh1: defaultCountry,
          furtherPerson: "",
          salutationKInh2: "",
          firstNameKInh2: "",
          lastNameKInh2: "",
          birthdayKInh2: "",
          streetKInh2: "",
          numberKInh2: "",
          postcodeKInh2: "",
          placeKInh2: "",
          countryKInh2: defaultCountry,
        };

        for (let k in initValues) {
          setDefaultValue(setter, k, initValues[k]);
        }
        setter("model", initValues.model);
        setter("carPrice", initValues.carPrice);

        setLoading(false);
      } catch (error) {
        push("/404");
      }
    };

    if (!error) {
      init();
    }
  }, [carType, push, search, locale, setLocale]);

  // Set data to localstorage when data is changed
  methods.watch((data, { name, type }) => {
    setLeasingFormState(data);
  });
  return loading ? (
    <LoadingPage />
  ) : (
    <FormProvider {...methods}>
      <LandingPage>
        {customer === "private" ? (
          <LeasingFormPrivate />
        ) : customer === "business" ? (
          <LeasingFormBusiness />
        ) : null}
      </LandingPage>
    </FormProvider>
  );
}
