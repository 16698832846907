import Header from "components/HeaderV2/HeaderV2";
import React, { Fragment, useState, useRef, useEffect } from "react";
import { Image } from "react-bootstrap";
import CarHolderImgSrc from "../../assets/carholder-white.png";
import CarBackgroundImgSrc from "../../assets/bg-car-holder.png";
import GeneralInformation from "./GeneralInformation";
import StickyCta from "components/CTA/StickyCta";
import FooterView from "./Footer";
import PaymentInformation from "./PaymentInformation";
import DurationInformation from "./DurationInformation";
import MileageInformation from "./MileageInformation";
import { getInitialValues, getOEMs } from "utils/api";
import {
  calcNomInterest,
  calcEffInterest,
  calcFinalValue,
  generateMailBody,
  getGridSystemForBody,
  getMaxMonthOnMonthChange,
  getOemByMileage,
  getOemValueByMileageAndMonth,
  isCurrResidualEqualsOrGreaterThanMaxResidual as isEqualOrGreater,
  leasingCalculatorDefaultState,
} from "./leasing-engine";
import "./leasingPublic.css";
import ImageComponent from "./ImageComponent";
import { useIntl } from "react-intl";
import useWindowSize from "hooks/useWindowSize";

function LeasingCalculator() {
  // Ref for scrolling effect
  const footerRef = useRef(null);
  const bodyRef = useRef(null);
  const ctaRef = useRef(null);
  const durationViewRef = useRef(null);
  const paymentViewRef = useRef(null);
  const mileageViewRef = useRef(null);

  // State
  const [isOpenModalCta, setIsOpenModalCta] = useState(false);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [availableOEMs, setAvailableOEMs] = useState([]);
  const [leasingCalculatorState, setLeasingCalculatorState] = useState(leasingCalculatorDefaultState);
  const [interestDataCache, setInterestDataCache] = useState([]);
  const [toggledOnTerms, setToggledOnTerms] = useState();
  
  // nomInterest
  const [fixedNomInterest, setFixedNomInterest] = useState(0);
  const [variableNomInterest, setVariableNomInterest] = useState(0);

  // termDependency
  const [termDependency, setTermDependency] = useState({});

  // Intl
  const { formatMessage } = useIntl();

  // CSS
  const [width, height] = useWindowSize();

  const isCalculatorAvailable = leasingCalculatorState.modelMarke && leasingCalculatorState.modelType;

  const jumpToView = (view) => {
    return view.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  useEffect(() => {
    fetchOEMs();
  }, []);

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  useEffect(() => {
    if (!document.querySelector(".cta2")) return;
    const cta = document.querySelector(".cta2");
    const footer = document.querySelector(".footer2").getBoundingClientRect();
    if (footer.top <= height) {
      isOpenModalCta
        ? cta.classList.add("open-modal")
        : cta.classList.remove("is-sticky");
    }
  }, [isOpenModalCta, height]);

  /* Method that will fix CTA after a specific scrollable */
  const isSticky = () => {
    if (!document.querySelector(".cta2") || !document.querySelector(".footer2"))
      return;

    const cta = document.querySelector(".cta2");
    const footer = document.querySelector(".footer2").getBoundingClientRect();
    const footerTop = footer.top;

    footerTop >= height
      ? cta.classList.add("is-sticky")
      : cta.classList.remove("is-sticky");
  };

  const fetchOEMs = async () => {
    const { data } = await getOEMs();
    const { OEMs } = data;
    if (OEMs && OEMs.length > 0) {
      const availableOEMs = OEMs.map((oem) => {
        const models = oem.models.map((model) => {
          return { label: model, value: model };
        });
        return { brand: { label: oem.name, value: oem.name }, models: models };
      });
      setAvailableOEMs(availableOEMs);
      const availableBrands = availableOEMs.map((oem) => {
        return { label: oem.brand.label, value: oem.brand.value };
      });
      setBrands(availableBrands);
    }
  };

  const fetchInterestData = async (value, typeOfCustomer) => {
    const { data } = await getInitialValues(value, typeOfCustomer);
    setInterest(data);
    setTerms(data);
    return data;
  };

  const setInterest = (value) => {
    const oemValue = getOemValueByMileageAndMonth(value.residualValuesOEM, leasingCalculatorState.currKm, leasingCalculatorState.currMonth);
    if (oemValue <= 0) return;
    const maxResidual = oemValue * leasingCalculatorState.price;
    const prePaymentMin = value.prePaymentFrom * leasingCalculatorState.price > value.reservationFee ? value.prePaymentFrom * leasingCalculatorState.price : value.reservationFee;
    const prePaymentMax = value.prePaymentTo * leasingCalculatorState.price;
    const prePayment = leasingCalculatorState.prePayment > prePaymentMax ? prePaymentMax : leasingCalculatorState.prePayment < prePaymentMin ? prePaymentMin : leasingCalculatorState.prePayment;
    const currResidual = leasingCalculatorState.currResidual < maxResidual ? leasingCalculatorState.currResidual < price * 0.05 ? price * 0.05 : leasingCalculatorState.currResidual : maxResidual;
    const interest = calcNomInterest(value.nomInterest, value.termDependencyArray, leasingCalculatorState.currMonth);
    setFixedNomInterest(value.nomInterest);
    setVariableNomInterest(interest);
    setTermDependency(value.termDependencyArray);
    setLeasingCalculatorState({
      ...leasingCalculatorState,
      modelType: value.model,
      customerType: value.customer,
      nomInterest: interest, // value.nomInterest,
      effInterest: calcEffInterest(interest), // calcEffInterest(value.nomInterest),
      prePaymentFrom: value.prePaymentFrom,
      prePaymentTo: value.prePaymentTo,
      reservationFee: value.reservationFee,
      prePayment,
      residualvalueFrom: value.residualvalueFrom,
      residualValuesOEM: value.residualValuesOEM,
      maxResidual: maxResidual,
      currResidual: leasingCalculatorState.currResidual === 0 ? maxResidual : currResidual, // initial value = maxResidual
      modelImageSrc: value.examplaryPicture,
      finalValue: calcFinalValue(
        interest, // value.nomInterest,
        leasingCalculatorState.currMonth,
        prePayment,
        leasingCalculatorState.price,
        leasingCalculatorState.currResidual === 0 ? maxResidual : currResidual
      ),
    });
  };

  const setTerms = (data) => {
    let includedTerms = Object.keys(data.termToggles).filter(key => data.termToggles[key]);
    includedTerms = includedTerms.map(x => ({ value: Number(x) })).reverse();
    setToggledOnTerms(includedTerms);
  }

  const handleOnBrandChange = (selectedBrand) => {
    const availableModels = availableOEMs.filter(
      (oem) => oem.brand.value === selectedBrand
    );
    if (availableModels.length > 0) {
      setModels(availableModels[0].models);
    }

    const newState = {
      ...leasingCalculatorState,
      modelMarke: selectedBrand,
      modelType: "",
    };
    setLeasingCalculatorState(newState);
  };

  const handleOnModelTypeChange = (value) => {
    handleFetchInterestData(value, leasingCalculatorState.customerType);
  };

  const handleOnCustomerTypeChange = async (value) => {
    // initial state, when user does not select any model type
    if (leasingCalculatorState.modelType === "") {
      setLeasingCalculatorState({
        ...leasingCalculatorState,
        customerType: value,
      });
      return;
    }
    handleFetchInterestData(leasingCalculatorState.modelType, value);
  };

  const handleFetchInterestData = async (model, customerType) => {
    if (!model || !customerType) return;
    // if interesDataCahe is empty,get data from API
    if (interestDataCache.length === 0) {
      const data = await fetchInterestData(model, customerType);
      setInterestDataCache([...interestDataCache, data]);
      return;
    }
    // if interesDataCahe is not empty,get data from cache
    if (interestDataCache.length > 0) {
      const data = interestDataCache.find(
        (item) => item.model === model && item.customer === customerType
      );
      // if interestDataCahe is not empty,but data is empty,get data from API
      if (!data) {
        const data = await fetchInterestData(model, customerType);
        setInterestDataCache([...interestDataCache, data]);
        return;
      } else {
        // get data from interestDataCahe
        setInterest(data);
        return;
      }
    }
  };

  const handleOnPriceChange = (value) => {
    const newPrice = value < 0 ? 0 : value;
    const oemValue = getOemValueByMileageAndMonth(
      leasingCalculatorState.residualValuesOEM,
      leasingCalculatorState.currKm,
      leasingCalculatorState.currMonth
    );
    const {
      prePaymentFrom,
      reservationFee,
      prePaymentTo,
      prePayment,
    } = leasingCalculatorState;
    if (oemValue <= 0) return;
    const newMaxResidual = oemValue * newPrice;
    const prePaymentMin =
      prePaymentFrom * newPrice > reservationFee
        ? prePaymentFrom * newPrice
        : reservationFee;
    const prePaymentMax = prePaymentTo * newPrice;
    const newPrePayment =
      prePayment > prePaymentMax
        ? prePaymentMax
        : prePayment < prePaymentMin
        ? prePaymentMin
        : prePayment;
    setLeasingCalculatorState({
      ...leasingCalculatorState,
      price: newPrice,
      maxResidual: newMaxResidual,
      currResidual: newMaxResidual,
      prePayment: newPrePayment,
      finalValue: calcFinalValue(
        leasingCalculatorState.nomInterest,
        leasingCalculatorState.currMonth,
        newPrePayment,
        newPrice,
        newMaxResidual
      ),
    });
  };

  const handleOnPrePaymentChange = (value) => {
    const prePaymentMax =
      leasingCalculatorState.prePaymentTo * leasingCalculatorState.price;
    const prePaymentMin =
      leasingCalculatorState.prePaymentFrom * leasingCalculatorState.price;
    const possiblePrePaymentMin =
      prePaymentMin > leasingCalculatorState.reservationFee
        ? prePaymentMin
        : leasingCalculatorState.reservationFee;
    const prePayment =
      value <= prePaymentMax
        ? value >= possiblePrePaymentMin
          ? value
          : possiblePrePaymentMin
        : prePaymentMax;
    setLeasingCalculatorState({
      ...leasingCalculatorState,
      prePayment,
      finalValue: calcFinalValue(
        leasingCalculatorState.nomInterest,
        leasingCalculatorState.currMonth,
        Math.round(prePayment),
        leasingCalculatorState.price,
        leasingCalculatorState.currResidual
      ),
    });
  };

  const handleOnCurrResidualChange = (value) => {
    const minResidual = leasingCalculatorState.price * 0.05;
    const currResidual =
      value <= leasingCalculatorState.maxResidual
        ? value >= minResidual
          ? value
          : minResidual
        : leasingCalculatorState.maxResidual;
    setLeasingCalculatorState({
      ...leasingCalculatorState,
      currResidual,
      finalValue: calcFinalValue(
        leasingCalculatorState.nomInterest,
        leasingCalculatorState.currMonth,
        leasingCalculatorState.prePayment,
        leasingCalculatorState.price,
        currResidual
      ),
    });
  };

  const handleOnMonthChange = (currMonth) => {
    const oemValue = getOemValueByMileageAndMonth(
      leasingCalculatorState.residualValuesOEM,
      leasingCalculatorState.currKm,
      currMonth
    );
    const oem = getOemByMileage(
      leasingCalculatorState.residualValuesOEM,
      leasingCalculatorState.currKm
    );
    if (oemValue <= 0 || oem === null) return;
    const maxResidual = oemValue * leasingCalculatorState.price;
    const currResidual =
      isEqualOrGreater(
        leasingCalculatorState.currResidual,
        leasingCalculatorState.maxResidual
      ) || isEqualOrGreater(leasingCalculatorState.currResidual, maxResidual)
        ? maxResidual
        : leasingCalculatorState.currResidual;
    const interest = calcNomInterest(fixedNomInterest, termDependency, currMonth);
    setVariableNomInterest(interest);
    setLeasingCalculatorState({
      ...leasingCalculatorState,
      currMonth,
      maxResidual,
      currResidual,
      nomInterest: interest,
      effInterest: calcEffInterest(interest),
      finalValue: calcFinalValue(
        interest, // leasingCalculatorState.nomInterest,
        currMonth,
        leasingCalculatorState.prePayment,
        leasingCalculatorState.price,
        currResidual
      ),
    });
  };

  const handleOnKMChange = (currKm) => {
    const maxMonth = getMaxMonthOnMonthChange(currKm);
    const currMonth =
      leasingCalculatorState.currMonth > maxMonth
        ? maxMonth
        : leasingCalculatorState.currMonth;

    const oemValue = getOemValueByMileageAndMonth(
      leasingCalculatorState.residualValuesOEM,
      currKm,
      currMonth
    );
    if (oemValue <= 0) return;

    const maxResidual = oemValue * leasingCalculatorState.price;
    const currResidual =
      isEqualOrGreater(
        leasingCalculatorState.currResidual,
        leasingCalculatorState.maxResidual
      ) || isEqualOrGreater(leasingCalculatorState.currResidual, maxResidual)
        ? maxResidual
        : leasingCalculatorState.currResidual;

    setLeasingCalculatorState({
      ...leasingCalculatorState,
      maxResidual,
      currResidual,
      currKm,
      currMonth,
      maxMonth,
      finalValue: calcFinalValue(
        leasingCalculatorState.nomInterest,
        currMonth,
        leasingCalculatorState.prePayment,
        leasingCalculatorState.price,
        currResidual
      ),
    });
  };

  const {
    price,
    maxResidual,
    modelMarke,
    modelType,
    currMonth,
    currKm,
    nomInterest,
    effInterest,
    currResidual,
    prePayment,
    finalValue,
    residualValuesOEM,
    prePaymentTo,
    modelImageSrc,
    reservationFee,
  } = leasingCalculatorState;

  const sendEmail = () => {
    const formattedBody = generateMailBody(
      formatMessage,
      modelType,
      price,
      prePayment,
      currMonth,
      currKm,
      currResidual,
      finalValue
    );
    const mailToLink =
      "mailto:support@leaseteq.ch?body=" + encodeURIComponent(formattedBody);
    window.location.href = mailToLink;
  };

  return (
    <div className="layout-root-publicleasing">
      <div
        className={
          isOpenModalCta ? "header-wrapper z-index-1" : "header-wrapper"
        }>
        <Header />
      </div>
      <div className="container-wrapper ">
        <div className="item-wrapp">
          <div
            className={`body-wrapper`}
            style={{
              gridTemplateColumns: `${getGridSystemForBody(width)}`,
            }}>
            <div className="space1"></div>
            <div
              className="body-left-wrapper"
              style={{ height: isCalculatorAvailable ? "100vh" : "80vh" }}>
              <div className="car-holder-wrapper">
                <div className="bg-car-holder">
                  {modelType !== "" ? (
                    modelImageSrc !== "" ? (
                      <Image src={modelImageSrc} alt="car-holder" />
                    ) : (
                      <ImageComponent carModel={modelType} />
                    )
                  ) : (
                    <Image src={CarHolderImgSrc} alt="car-holder" />
                  )}
                  <div className="car-holder-content">
                    <Image
                      src={CarBackgroundImgSrc}
                      alt="car-holder"
                      style={{ paddingRight: "40px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="space2"></div>
            <div className={`body-right-wrapper`} ref={bodyRef}>
              <GeneralInformation
                brands={brands}
                selectedBrand={modelMarke}
                models={models}
                selectedModel={modelType}
                onBrandChange={handleOnBrandChange}
                onModelChange={handleOnModelTypeChange}
                onCustomerTypeChange={handleOnCustomerTypeChange}
                goToPaymentView={() => jumpToView(paymentViewRef)}
              />
              {isCalculatorAvailable && (
                <Fragment>
                  <PaymentInformation
                    maxResidual={maxResidual}
                    price={price}
                    onPriceChange={handleOnPriceChange}
                    prePayment={prePayment}
                    onPrePaymentChange={handleOnPrePaymentChange}
                    currResidual={currResidual}
                    onCurrResidualChange={handleOnCurrResidualChange}
                    paymentViewRef={paymentViewRef}
                    goToDurationView={() => jumpToView(durationViewRef)}
                    prePaymentMax={prePaymentTo * price}
                    prePaymentMin={reservationFee}
                    showTeslaConfigurator={modelMarke === "Tesla"}
                  />
                  <DurationInformation
                    months={toggledOnTerms}
                    selectedMonth={currMonth}
                    onMonthChange={handleOnMonthChange}
                    durationViewRef={durationViewRef}
                    goToMileageView={() => jumpToView(mileageViewRef)}
                    currKm={currKm}
                    nomInterest={nomInterest}
                    prePayment={prePayment}
                    price={price}
                    currResidual={currResidual}
                    finalValue={finalValue}
                  />
                  <MileageInformation
                    selectedMileage={currKm}
                    onMileageChange={handleOnKMChange}
                    mileageViewRef={mileageViewRef}
                    nomInterest={nomInterest}
                    prePayment={prePayment}
                    price={price}
                    currResidual={currResidual}
                    finalValue={finalValue}
                    currMonth={currMonth}
                    residualValuesOEM={residualValuesOEM}
                    sendEmail={sendEmail}
                    showTeslaConfigurator={modelMarke === "Tesla"}
                  />
                </Fragment>
              )}
            </div>
            <div className="space3"></div>
          </div>
        </div>
      </div>
      {isCalculatorAvailable ? (
        <div>
          <div
            className={isOpenModalCta ? "cta2 z-index-2" : "cta2 is-sticky"}
            ref={ctaRef}>
            <StickyCta
              model={modelType}
              subTitle={formatMessage({
                id: "publicleasing.text.cta-leasingrate",
              })}
              price={price}
              leasePrice={finalValue}
              prePayment={prePayment}
              currMonth={currMonth}
              currKm={currKm}
              currency="CHF"
              nomInterest={nomInterest}
              effInterest={effInterest}
              currResidual={currResidual}
              setIsOpenModalCta={setIsOpenModalCta}
              isOpenModalCta={isOpenModalCta}
              sendEmail={sendEmail}
              showTeslaConfigurator={modelMarke === "Tesla"}
            />
          </div>
          <div className="footer2">
            <FooterView refProp={footerRef} />
          </div>
        </div>
      ) : (
        <div className="footer-wrapper">
          <FooterView refProp={footerRef} />
        </div>
      )}
    </div>
  );
}

export default LeasingCalculator;
